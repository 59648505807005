import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'

const validateTenantHandler = rest.get(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/leads/:tenant`,
  (req, res, ctx) => {
    const { tenant } = req.params

    if (['let', 'lemontech', 'tbx'].includes(tenant))
      return res(ctx.status(404), ctx.json(null))

    return res(ctx.json(null))
  }
)

export default validateTenantHandler

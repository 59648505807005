import {
  FormControlProps,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormLabel,
  InputRightElement,
  InputGroup,
  Icon,
  FormErrorMessage,
} from '@chakra-ui/react'
import { forwardRef, ReactNode } from 'react'
import { FaCheck } from 'react-icons/fa'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

type InputGroupProps = {
  label: string
  isValid?: boolean
  errorMessage?: string
  suffix?: ReactNode
  prefixInput?: ReactNode
  helperMessage?: ReactNode
}
type InputProps = ChakraInputProps & FormControlProps & InputGroupProps

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      isValid,
      label,
      suffix,
      prefixInput,
      isInvalid,
      isRequired,
      errorMessage,
      helperMessage,
      id,
      ...rest
    },
    ref
  ) => (
    <FormControl id={id} isRequired={isRequired} isInvalid={isInvalid}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <InputGroup
        color={
          isValid
            ? 'support.success'
            : isInvalid
            ? 'support.error'
            : 'secondary.02'
        }
      >
        {prefixInput}

        <ChakraInput
          {...rest}
          errorBorderColor="support.error"
          ref={ref}
          color="secondary.02"
          fontSize="sm"
          focusBorderColor="primary.03"
          aria-label={id}
          _hover={
            isValid || isInvalid
              ? undefined
              : { borderColor: 'primary.03', shadow: 'md' }
          }
          borderColor={isValid ? 'support.success' : 'primary.03'}
          boxShadow={
            isValid
              ? '0 0 0 1px var(--chakra-colors-support-success)'
              : undefined
          }
        />

        <InputRightElement
          display={!suffix && isValid ? 'flex' : 'none'}
          children={<Icon color="support.success" as={FaCheck} fontSize="sm" />}
        />

        <InputRightElement
          display={!suffix && isInvalid ? 'flex' : 'none'}
          children={
            <Icon
              color="support.error"
              as={HiOutlineExclamationCircle}
              fontSize="xl"
            />
          }
        />

        {suffix}
      </InputGroup>

      {helperMessage}

      <FormErrorMessage
        display={errorMessage ? 'flex' : 'none'}
        mt="1"
        position="absolute"
        fontSize="xs"
        color="support.error"
      >
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  )
)

export default Input

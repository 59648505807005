import { CONSTANTS } from 'configs/constants'
import axios from 'axios'

export const fetcher = axios.create({
  baseURL: CONSTANTS.THE_ADMIN_API_URL,
})

fetcher.interceptors.response.use((response) => {
  return response?.data
})

import { CONSTANTS } from 'configs/constants'
import {
  Box,
  Image,
  Text,
  UnorderedList,
  ListItem,
  Link,
  Center,
} from '@chakra-ui/react'
import logoImg from 'assets/img/logo.svg'
import dashboardImg from 'assets/img/dashboard.svg'
import { useTranslation } from 'react-i18next'

const PremadeInfo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box
      w="50%"
      pt="12"
      pb="12"
      pl="4"
      pr="24"
      display={{ base: 'none', lg: 'block' }}
      data-testid="PremadeInfo"
    >
      <Image src={logoImg} maxW="150px" />
      <Text
        fontWeight="bold"
        color="white"
        fontSize="3xl"
        lineHeight="short"
        mt="6"
      >
        {t('premade.title')}
      </Text>
      <Text color="white" fontSize="m" lineHeight="short" mt="6">
        {t('premade.description')}
      </Text>
      <Text color="white" fontSize="m" lineHeight="short" mt="6">
        {t('premade.advantages.title')}
      </Text>
      <UnorderedList spacing={1} mt="1" color="white">
        <ListItem fontSize="m">{t('premade.advantages.01')}</ListItem>
        <ListItem fontSize="m">{t('premade.advantages.02')}</ListItem>
        <ListItem fontSize="m">{t('premade.advantages.03')}</ListItem>
      </UnorderedList>
      <Center h="300px">
        <Image src={dashboardImg} maxW="350px" mt="6" />
      </Center>
      <Text color="white" fontSize="m" lineHeight="short" mt="6">
        {t('premade.tour')}{' '}
        <Link fontWeight="bold" href={CONSTANTS.CONTACT_URL}>
          {t('premade.tour_link_text')}
        </Link>
        {t('premade.footer')}
      </Text>
    </Box>
  )
}

export default PremadeInfo

import { Box, Image, Text, List, ListItem, ListIcon } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import logoImg from 'assets/img/logo.svg'
import { useTranslation } from 'react-i18next'

const Info: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box
      w="50%"
      p="16"
      display={{ base: 'none', lg: 'block' }}
      data-testid="Info"
    >
      <Image src={logoImg} maxW="253px" />
      <Text
        fontWeight="semibold"
        color="white"
        fontSize="2xl"
        lineHeight="short"
        mt="14"
      >
        {t('description')}
      </Text>

      <List spacing={6} mt="16" color="white">
        <ListItem>
          <ListIcon as={FaCheck} color="primary.02" />
          {t('advantages.01')}
        </ListItem>
        <ListItem>
          <ListIcon as={FaCheck} color="primary.02" />
          {t('advantages.02')}
        </ListItem>
        <ListItem>
          <ListIcon as={FaCheck} color="primary.02" />
          {t('advantages.03')}
        </ListItem>
        <ListItem>
          <ListIcon as={FaCheck} color="primary.02" />
          {t('advantages.04')}
        </ListItem>
      </List>
    </Box>
  )
}

export default Info

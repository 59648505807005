import ConfigsWrapper from 'configs/ConfigsWrapper'
import JoinPage from 'features/join'
import React from 'react'
import ReactDOM from 'react-dom'
import AppProvider from './state'

ReactDOM.render(
  <React.StrictMode>
    <ConfigsWrapper>
      <AppProvider>
        <JoinPage />
      </AppProvider>
    </ConfigsWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)

import { currency } from './../types/currencyType'
import { fetcher } from 'features/shared/utils/fetcher'

async function getCurrencies() {
  const data = await fetcher.get<currency[]>('/v1/products/currencies')

  return data
}

export default getCurrencies

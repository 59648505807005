export const CONSTANTS = {
  PUBLIC_URL: process.env.PUBLIC_URL,
  IS_MOCK_ENV:
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_ENV === 'mock',
  THE_ADMIN_API_URL: process.env.REACT_APP_THE_ADMIN_API_URL,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER || '',
  CONTACT_URL: process.env.REACT_APP_CONTACT_URL,
}

import Layout from '../components/Layout'
import Info from '../components/Info'
import PremadeInfo from '../components/PremadeInfo'
import getQueryParams from 'utils/getQueryParams'
import Form from '../components/Form'
import PremadeForm from '../components/PremadeForm'

const JoinPage: React.FC = () => {
  const demoType = getQueryParams().get('demoType')

  return (
    <Layout>
      {demoType === 'premade' ? <PremadeInfo /> : <Info />}
      {demoType === 'premade' ? <PremadeForm /> : <Form />}
    </Layout>
  )
}

export default JoinPage

import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'
import { build, fake, sequence } from '@jackfranklin/test-data-bot'
import { createTenantProps } from 'features/join/services/createTenant'

const leadBuilder = (props: createTenantProps) =>
  build({
    fields: {
      id: sequence((n) => n),
      owner_name: props.owner_name,
      owner_email: props.owner_email,
      owner_phone: props.owner_email,
      subdomain: props.subdomain,
      locale: props.subdomain,
      product_id: fake((f) => f.random.number()),
      plan_id: fake((f) => f.random.number()),
      country_id: fake((f) => f.random.number()),
      created_at: fake((f) => f.date.recent()),
      updated_at: fake((f) => f.date.recent()),
      owner_password: props.owner_password,
      jid: fake((f) => f.random.uuid()),
      status: 'queued',
      login_token: fake((f) => f.random.uuid()),
      recaptcha: props.recaptcha,
    },
  })()

const createTenantHandler = rest.post<createTenantProps>(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/leads`,
  (req, res, ctx) => {
    return res(ctx.json(leadBuilder(req.body)))
  }
)

export default createTenantHandler

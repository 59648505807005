import { FormHelperText, Icon } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'

type PasswordErrorType = {
  types: object
}

interface HelperTextProps {
  passwordErrors: PasswordErrorType
  isTouchedAndDirty: boolean
  passwordErrorType: string
  text: string
}

const FormPasswordHelperText: React.FC<HelperTextProps> = ({
  passwordErrors,
  isTouchedAndDirty,
  passwordErrorType,
  text,
}) => (
  <FormHelperText fontSize="sm" color="secondary.02">
    <Icon
      color={
        isTouchedAndDirty && !passwordErrors?.types[passwordErrorType]
          ? 'support.success'
          : 'neutral.02'
      }
      as={FaCheckCircle}
    />{' '}
    {text}
  </FormHelperText>
)

export default FormPasswordHelperText

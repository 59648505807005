import { Flex, Spinner } from '@chakra-ui/react'

const PageLoader: React.FC = () => {
  return (
    <Flex
      w="full"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      data-testid="PageLoader"
    >
      <Spinner color="blue.500" size="xl" />
    </Flex>
  )
}

export default PageLoader

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import isTenantAvailable from '../services/isTenantAvailable'
import { useTranslation } from 'react-i18next'

function usePremadeFormWithValidations(props: {
  minimunPasswordCharacters: number
  minimunSubdomainCharacters: number
}) {
  const { t } = useTranslation()

  return useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(t('form.validations.required')),
        email: yup
          .string()
          .required(t('form.validations.required'))
          .matches(
            /^[A-Za-z0-9@_.+-]*$/,
            t('form.validations.special_characters')
          )
          .email(t('form.validations.email')),
        password: yup
          .string()
          .required(t('form.validations.required'))
          .min(props.minimunPasswordCharacters)
          .test(
            'upperCaseTest',
            t('form.validations.password_uppercase'),
            (password) => !!password?.match(/[A-ZÑ]/)
          )
          .test(
            'lowerCaseTest',
            t('form.validations.password_lowercase'),
            (password) => !!password?.match(/[a-zñ]/)
          )
          .test(
            'numberTest',
            t('form.validations.password_number'),
            (password) => !!password?.match(/[0-9]/)
          )
          .test(
            'specialCharacterTest',
            t('form.validations.password_special_character'),
            (password) => !!password?.match(/[^a-zA-Z0-9\s\u00C0-\u00FF]/)
          ),
        phone: yup.string().required(t('form.validations.required')),
        country: yup.object().required(t('form.validations.required')),
        confirm: yup.boolean().oneOf([true], t('form.validations.required')),
        subdomain: yup
          .string()
          .required(t('form.validations.required'))
          .min(
            props.minimunSubdomainCharacters,
            t('form.validations.minimun_characters', {
              characters: props.minimunSubdomainCharacters,
            })
          )
          .matches(/^[A-Za-z0-9]*$/, t('form.validations.special_characters'))
          .test(
            'isTenantAvailable',
            ({ value }) => t('form.validations.tenant', { tenant: value }),
            (tenant) => isTenantAvailable(`demo${tenant}`)
          ),
      })
    ),
  })
}

export default usePremadeFormWithValidations

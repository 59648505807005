import Select, { SelectProps, SelectOption } from 'components/Select'
import {
  GroupTypeBase,
  components,
  OptionProps,
  SingleValueProps,
} from 'react-select'
import * as flag from 'react-flags-select'
import toPascalCase from 'utils/toPascalCase'
import { Flex, Box } from '@chakra-ui/react'
import { forwardRef } from 'react'

type FlagSelectOption = SelectOption & { iso2: string }

interface SelectFlagProps {
  options?: FlagSelectOption[]
}

export const Flag: React.FC<{ iso2: string }> = ({ iso2 }) => {
  const flagName = toPascalCase(iso2)
  const FlagComponent = (flag as any)[flagName]

  if (FlagComponent) return <FlagComponent />

  return <Box w="4" h="3" bg="gray.400" />
}

const { Option, SingleValue } = components
const IconOption: React.FC<OptionProps<any, false, GroupTypeBase<any>>> = (
  props
) => {
  return (
    <Option {...props}>
      <Flex alignItems="center" data-testid="SelectFlag-Option">
        <Box fontSize="sm">
          <Flag iso2={props.data.iso2} />
        </Box>
        <Box ml="2">{props.data.label}</Box>
      </Flex>
    </Option>
  )
}

const IconSingleValue: React.FC<SingleValueProps<any, GroupTypeBase<any>>> = (
  props
) => {
  return (
    <SingleValue {...props}>
      <Flex alignItems="center" data-testid="SelectFlag-SingleValue">
        <Box fontSize="sm">
          <Flag iso2={props.data.iso2} />
        </Box>
        <Box ml="2">{props.data.label}</Box>
      </Flex>
    </SingleValue>
  )
}

const SelectFlag: React.FC<SelectProps & SelectFlagProps> = forwardRef<
  HTMLElement,
  SelectProps & SelectFlagProps
>((props, ref) => {
  return (
    <Select
      {...props}
      ref={ref as any}
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
    />
  )
})

export default SelectFlag

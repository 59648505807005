import { extendTheme, theme, ThemeOverride } from '@chakra-ui/react'

const overrides: ThemeOverride = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    body: `'Open Sans',-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    heading: "'Open Sans', sans-serif",
  },
  colors: {
    primary: {
      '01': '#3783ED',
      '02': '#0E82F5',
      '03': '#6D98DA',
      '04': '#0048B0',
    },
    secondary: { '01': '#11161A', '02': '#3C506B' },
    neutral: {
      '01': '#CED4DA',
      '02': '#8993A4',
    },
    support: {
      error: '#A00614',
      success: '#0C5F1E',
    },
  },
}

export default extendTheme(overrides, theme)

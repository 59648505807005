import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'
import { build, fake } from '@jackfranklin/test-data-bot'

const timeZone = build({
  fields: {
    id: fake((f) => f.finance.currencyCode()),
    name: fake((f) => f.finance.currencyName()),
    symbol: fake((f) => f.finance.currencySymbol()),
    delimiter: '.',
    separator: ',',
    precision: 2,
  },
})

const timeZonesBuilder = build({
  fields: [],
  postBuild: () => {
    return [
      {
        id: 'PEN',
        name: 'Peruvian sol',
        symbol: 's/',
        delimiter: '.',
        separator: ',',
        precision: 2,
      },
      ...Array(9)
        .fill(undefined)
        .map((_) => timeZone()),
    ]
  },
})

const currenciesHandler = rest.get(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/products/currencies`,
  (_, res, ctx) => {
    return res(ctx.json(timeZonesBuilder()))
  }
)

export default currenciesHandler

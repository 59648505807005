import { fetcher } from 'features/shared/utils/fetcher'
import { timeZone } from '../types/timeZoneType'

async function getTimeZones() {
  const data = await fetcher.get<timeZone[]>('/v1/products/timezones')

  return data
}

export default getTimeZones

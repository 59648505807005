import { Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'
import FormLayout from './FormLayout'

const FormLoader: React.FC = () => (
  <FormLayout>
    <Stack spacing="8" data-testid="FormLoader">
      <Skeleton height="54px" />
      <Skeleton height="54px" />
      <Skeleton height="54px" />
      <Skeleton height="54px" />
      <Skeleton height="54px" />
      <Skeleton height="54px" />
      <Skeleton height="54px" />
      <Skeleton height="54px" />
    </Stack>
  </FormLayout>
)

export default FormLoader

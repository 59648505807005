import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'

const locationHandler = rest.get(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/location`,
  (_, res, ctx) => {
    return res(
      ctx.json({
        country: 'PE',
        currency: 'PEN',
        timezone: 'America/Lima',
      })
    )
  }
)

export default locationHandler

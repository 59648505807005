import TagManager from 'react-gtm-module'
import { CONSTANTS } from 'configs/constants'
import { currency } from './../types/currencyType'
import { timeZone } from './../types/timeZoneType'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getCountries from '../services/getCountries'
import getCurrentLocation from '../services/getCurrentLocation'
import { country } from '../types/countryType'
import getTimeZones from '../services/getTimeZones'
import getCurrencies from '../services/getCurrencies'
import getQueryParams from 'utils/getQueryParams'
import { fieldList, fieldListCountry } from '../types/fieldListType'

type status = 'idle' | 'pending' | 'success' | 'error'
type data = {
  countries: fieldListCountry[]
  timezones: fieldList[]
  currencies: fieldList[]
  languages: fieldList[]
  purposes: fieldList[]
}

function useFormListFieldsData() {
  const { t, i18n } = useTranslation()
  const [status, setStatus] = useState<status>('idle')
  const [data, setData] = useState<data>({
    countries: [],
    timezones: [],
    currencies: [],
    languages: [],
    purposes: [],
  })

  async function setFieldsData() {
    setStatus('pending')

    try {
      const isSupport = getQueryParams().get('area') === 'support'
      const [currentLocation, countries, currentTimeZone, currencies] =
        await Promise.all([
          getCurrentLocation(),
          getCountries(),
          getTimeZones(),
          getCurrencies(),
        ])
      const countriesField = getCountriesField(
        countries,
        currentLocation.country,
        isSupport
      )
      const timeZonesField = getTimeZonesField(
        currentTimeZone,
        currentLocation.timezone,
        isSupport
      )
      const currenciesField = getCurrenciesField(
        currencies,
        currentLocation.currency,
        isSupport
      )
      const languagesField = getLanguagesField(isSupport)
      const purposesField = getPurposesField()

      setData({
        countries: countriesField,
        languages: languagesField,
        purposes: purposesField,
        timezones: timeZonesField,
        currencies: currenciesField,
      })
      setStatus('success')
    } catch {
      setStatus('error')
    }
  }

  function getCountriesField(
    countries: country[],
    currentCountry: string,
    isSupport: boolean
  ) {
    const countriesField: fieldListCountry[] = countries.map(
      ({ iso_2, name }) => ({
        iso2: iso_2,
        label: name,
        value: iso_2.toUpperCase(),
        default: isSupport
          ? false
          : currentCountry.toLowerCase() === iso_2.toLowerCase(),
      })
    )

    return countriesField
  }

  function getLanguagesField(isSupport: boolean) {
    const currentLanguage = i18n.language
    const languages: fieldList[] = [
      {
        label: t('form.spanish'),
        value: 'es',
        default: isSupport ? false : currentLanguage.includes('es'),
      },
      {
        label: t('form.english'),
        value: 'en',
        default: isSupport ? false : currentLanguage.includes('en'),
      },
    ]

    return languages
  }

  function getPurposesField() {
    const purposes: fieldList[] = [
      {
        label: t('form.purpose_field.time_tracking'),
        value: 'time_tracking',
        default: true,
      },
      {
        label: t('form.purpose_field.project_management'),
        value: 'project_management',
        default: false,
      },
    ]

    return purposes
  }

  function getTimeZonesField(
    timeZones: timeZone[],
    currentTimeZone: string,
    isSupport: boolean
  ) {
    const timeZonesField: fieldList[] = timeZones.map(
      ({ id, name, offset }) => ({
        label: `(UTC ${offset}) ${name}`,
        value: id,
        default: isSupport
          ? false
          : currentTimeZone.toLowerCase() === id.toLowerCase(),
      })
    )

    return timeZonesField
  }

  function getCurrenciesField(
    currencies: currency[],
    currentCurrency: string,
    isSupport: boolean
  ) {
    const currenciesField: fieldList[] = currencies.map(({ id, name }) => ({
      label: `${name} (${id})`,
      value: id,
      default: isSupport
        ? false
        : currentCurrency.toLowerCase() === id.toLowerCase(),
    }))

    return currenciesField
  }

  useEffect(() => {
    setFieldsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (status === 'success') {
      TagManager.initialize({ gtmId: CONSTANTS.GOOGLE_TAG_MANAGER_ID })
    }
  }, [status])

  return {
    isLoading: status === 'idle' || status === 'pending',
    isSucess: status === 'success',
    isError: status === 'error',
    data,
    refetch: setFieldsData,
  }
}

export default useFormListFieldsData

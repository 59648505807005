import { Box, Text, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import getQueryParams from 'utils/getQueryParams'

interface FormLayoutProps {
  onSubmit?: React.FormEventHandler<HTMLDivElement> &
    React.FormEventHandler<HTMLFormElement>
}

const FormLayout: React.FC<FormLayoutProps> = ({ children, onSubmit }) => {
  const premade = getQueryParams().get('demoType') === 'premade'
  const { t } = useTranslation()

  return (
    <Box w={{ lg: '50%' }} p={{ base: '4', sm: '16' }} data-testid="FormLayout">
      <Text as="h1" fontSize="2xl" fontWeight="bold" color="secondary.02">
        {t('form.title')}
      </Text>

      {premade ? (
        <Text fontSize="sm" color="secondary.02" mt="4">
          {t('form.premade_statement')}{' '}
        </Text>
      ) : (
        <Text fontSize="sm" color="secondary.02" mt="4">
          {t('form.have_account')}{' '}
          <Link
            href={t('form.sign_in_link')}
            color="primary.01"
            fontWeight="bold"
            tabIndex={-1}
          >
            {t('form.sign_in')}
          </Link>
        </Text>
      )}

      <Box mt="8" as="form" onSubmit={onSubmit}>
        {children}
      </Box>
    </Box>
  )
}

export default FormLayout

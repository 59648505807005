import { Stack, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormLayout from './FormLayout'

const FormCreating: React.FC = () => {
  const { t } = useTranslation()

  return (
    <FormLayout>
      <Stack spacing="4" data-testid="FormCreating">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="neutral.01"
          color="primary.01"
          size="xl"
        />
        <Text fontSize="2xl" color="secondary.02">
          {t('creation_progress.title')}
        </Text>
        <Text fontSize="l" color="secondary.02">
          {t('creation_progress.description')}
        </Text>
        <Text fontSize="sm" color="secondary.02">
          {t('creation_progress.text')}
        </Text>
      </Stack>
    </FormLayout>
  )
}

export default FormCreating

import { fetcher } from 'features/shared/utils/fetcher'

export type createTenantProps = {
  owner_name: string
  owner_email: string
  owner_password: string
  owner_phone: string
  plan_code: string
  subdomain: string
  country_code: string
  timezone?: string
  metadata?: object
  hubspot_utk?: string
  currency_code?: string
  locale: string
  recaptcha: string
}

async function createTenant(props: createTenantProps) {
  const lead = await fetcher.post<{ id: string; login_token: string }>(
    '/v1/leads',
    props
  )

  await new Promise<void>((resolve, reject) => {
    ;(async function waitForCompleted() {
      try {
        const retryTimeInMS = 2000
        const { status } = await fetcher.get<{
          status: 'complete'
        }>(`/v1/leads/${lead.id}/status`)

        if (status === 'complete') return resolve()

        setTimeout(waitForCompleted, retryTimeInMS)
      } catch (error) {
        reject(error)
      }
    })()
  })

  const { tenant } = await fetcher.get<{ tenant: string }>(
    `/v1/leads/${lead.id}/tenant`
  )

  return `${tenant}/sessions/login_signup?token=${lead.login_token}`
}

export default createTenant

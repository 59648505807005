import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'

const statusTenantHandler = rest.get(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/leads/*/status`,
  (_, res, ctx) => {
    return res(ctx.json({ status: 'complete' }))
  }
)

export default statusTenantHandler

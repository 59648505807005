import { FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react'
import RSelect, { GroupTypeBase, Styles } from 'react-select'
import { forwardRef, useEffect } from 'react'
import { SelectComponents } from 'react-select/src/components'

export type SelectOption = { label: string; value: string }

export interface SelectProps {
  isRequired?: boolean
  label: string
  errorMessage?: string
  isValid?: boolean
  isInvalid?: boolean
  options?: SelectOption[]
  components?: Partial<SelectComponents<any, false, GroupTypeBase<any>>>
  ref?: React.Ref<HTMLElement>
  placeholder?: string
  defaultValue?: SelectOption
  onChange?: (e: SelectOption) => void
  id?: string
}

type stylesStates = 'default' | 'isFocused' | 'isValid' | 'isInvalid'

const Select: React.FC<SelectProps> = forwardRef<HTMLElement, SelectProps>(
  (
    {
      isRequired,
      label,
      errorMessage,
      isValid,
      isInvalid,
      defaultValue,
      onChange,
      id,
      ...rest
    },
    ref
  ) => {
    const selectStyles: Partial<Styles<any, false, GroupTypeBase<any>>> = {
      control: (styles, state) => {
        let currentState: stylesStates = 'default'
        if (state.isFocused) currentState = 'isFocused'
        if (isValid) currentState = 'isValid'
        if (isInvalid) currentState = 'isInvalid'

        const stateStyles = {
          borderColor: {
            isFocused: 'var(--chakra-colors-primary-03)',
            isValid: 'var(--chakra-colors-support-success)',
            isInvalid: 'var(--chakra-colors-support-error)',
            default: 'var(--chakra-colors-primary-03)',
          },
          boxShadow: {
            isFocused: '0 0 0 1px var(--chakra-colors-primary-03)',
            isValid: '0 0 0 1px var(--chakra-colors-support-success)',
            isInvalid: '0 0 0 1px var(--chakra-colors-support-error)',
            default: 'none',
          },
        }

        return {
          ...styles,
          transition: 'all 0.2s',
          borderRadius: 'var(--chakra-radii-md)',
          fontSize: 'var(--chakra-fontSizes-sm)',
          padding: '0 var(--chakra-space-2)',
          borderColor: stateStyles.borderColor[currentState],
          boxShadow: stateStyles.boxShadow[currentState],

          '&:hover':
            isValid || isInvalid
              ? undefined
              : {
                  boxShadow: 'var(--chakra-shadows-md)',
                  borderColor: 'var(--chakra-colors-primary-03)',
                },
        }
      },
      indicatorSeparator: () => ({ display: 'none' }),
      dropdownIndicator: (styles) => {
        let currentState: stylesStates = 'default'
        if (isValid) currentState = 'isValid'
        if (isInvalid) currentState = 'isInvalid'

        const stateStyles = {
          color: {
            isValid: 'var(--chakra-colors-support-success)',
            isInvalid: 'var(--chakra-colors-support-error)',
            default: 'var(--chakra-colors-gray-500)',
          },
        }

        return {
          ...styles,
          color: stateStyles.color[currentState],
          '&:hover': {
            color: stateStyles.color[currentState],
          },
        }
      },
      placeholder: (styles) => ({
        ...styles,
        color: 'var(--chakra-colors-gray-500)',
      }),
      menu: (styles) => ({
        ...styles,
        zIndex: 'var(--chakra-zIndices-dropdown)' as any,
      }),
    }

    useEffect(() => {
      if (defaultValue && onChange) onChange(defaultValue)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <FormControl
        isRequired={isRequired}
        isInvalid={isInvalid}
        data-testid="Select"
        id={id}
      >
        <FormLabel fontSize="sm" fontWeight="semibold">
          {label}
        </FormLabel>

        <RSelect
          ref={ref as any}
          {...rest}
          defaultValue={defaultValue}
          onChange={onChange}
          styles={selectStyles}
          inputId={id}
        />

        <FormErrorMessage
          display={errorMessage ? 'flex' : 'none'}
          mt="1"
          position="absolute"
          fontSize="xs"
          color="support.error"
        >
          {errorMessage}
        </FormErrorMessage>
      </FormControl>
    )
  }
)

export default Select

import {
  Link,
  GridItem,
  Grid,
  Button,
  InputRightAddon,
  InputLeftAddon,
  useBreakpointValue,
  InputRightElement,
  IconButton,
  Checkbox,
  Text,
  Icon,
  useToast,
  Flex,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Controller } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import Input from 'components/Input'
import Select from 'components/Select'
import SelectFlag from 'components/SelectFlag'
import { useTranslation } from 'react-i18next'
import useFormListFieldsData from '../hooks/useFormListFieldsData'
import FormLayout from './FormLayout'
import FormLoader from './FormLoader'
import FormCreating from './FormCreating'
import FormPasswordHelperMessages from './FormPasswordHelperMessages'
import createTenant from '../services/createTenant'
import { fieldList, fieldListCountry } from '../types/fieldListType'
import usePremadeFormWithValidations from '../hooks/usePremadeFormWithValidations'
import FormError from './FormError'
import { CONSTANTS } from 'configs/constants'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
import usePreventLeavingSite from 'hooks/usePreventLeavingSite'
import { hubspotUtk } from 'features/shared/utils/hubspot'

type FormValues = {
  name: string
  email: string
  password: string
  phone: string
  subdomain: string
  country: fieldListCountry
  purpose: fieldList
  confirm: boolean
}

const PremadeForm: React.FC = () => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage('es')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const toast = useToast()
  const listFieldsData = useFormListFieldsData()

  const minimunPasswordCharacters = 8
  const minimunSubdomainCharacters = 2

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: {
      errors,
      isSubmitting,
      dirtyFields,
      touchedFields,
      isValid: isFormValid,
    },
  } = usePremadeFormWithValidations({
    minimunPasswordCharacters,
    minimunSubdomainCharacters,
  })

  const [showPassword, setShowPassword] = useState(false)
  const handleClickEye = () => setShowPassword(!showPassword)
  const buttonSize = useBreakpointValue({ base: 'md', sm: 'lg' })

  const getDefaultOption = (options: any[]) =>
    options.find((option) => option.default)

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const domain = '.timebillingapp.com'
  const watchedSubdomain = watch('subdomain')
  const watchedEmail = watch('email')
  const confirm = watch('confirm')

  const canNotLeaveSite = isFormValid || !!Object.keys(touchedFields).length

  const preventLeavingSite = usePreventLeavingSite(canNotLeaveSite)

  const onSubmit = handleSubmit(
    async ({
      country,
      name,
      email,
      password,
      phone,
      subdomain,
      purpose,
    }: FormValues) => {
      const recaptcha = (await recaptchaRef?.current?.executeAsync()) as string
      recaptchaRef?.current?.reset()

      try {
        const loggedTenantUrl = await createTenant({
          subdomain: `demo${subdomain}`,
          plan_code: 'PREMADE',
          owner_name: name,
          owner_email: email,
          owner_password: password,
          owner_phone: phone,
          country_code: country.value,
          locale: 'es',
          metadata: { demo_purpose: purpose.value },
          hubspot_utk: hubspotUtk(email),
          recaptcha,
        })

        const goToTenant = () => {
          window.location.href = loggedTenantUrl
        }

        preventLeavingSite.byPass(goToTenant)
      } catch {
        toast({
          title: t('form.register_error'),
          status: 'error',
          isClosable: true,
        })
      }
    }
  )

  const isTouchedAndDirtyField = (field: string) => {
    const isDirty = dirtyFields?.[field]
    const isTouched = touchedFields?.[field]

    return isDirty && isTouched
  }

  const getFieldValidations = (
    field: string,
    options?: { isTouched?: boolean; hideErrorMessage?: boolean }
  ) => {
    const errorMessage = errors?.[field]?.message
    const isDirty = dirtyFields?.[field]
    const isInvalid = !!errorMessage
    const isValid = options?.isTouched
      ? isTouchedAndDirtyField(field) && !isInvalid
      : isDirty && !isInvalid

    return {
      isInvalid,
      isValid: isFormValid || isValid,
      errorMessage: options?.hideErrorMessage ? undefined : errorMessage,
      id: field,
    }
  }

  useEffect(() => {
    setDomainEmailToSubdomain()

    function blackList(givenDomain: string) {
      const regex = /gmail|hotmail|yahoo/

      return regex.test(givenDomain)
    }

    function setDomainEmailToSubdomain() {
      if (blackList(watchedEmail)) return

      if (dirtyFields?.email && !errors?.email) {
        setValue('subdomain', getDomainFromEmail(watchedEmail), {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    }

    function getDomainFromEmail(givenEmail: string) {
      return givenEmail.replace(/.*@/, '').split('.')[0]
    }
  }, [dirtyFields?.email, errors?.email, watchedEmail, setValue])

  useEffect(() => {
    uncheckConfirm()

    function uncheckConfirm() {
      setValue('confirm', false)
    }
  }, [watchedSubdomain, setValue])

  if (listFieldsData.isLoading) {
    return <FormLoader />
  }

  if (isSubmitting) {
    return (
      <>
        <FormCreating />
        <ReCAPTCHA
          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={recaptchaRef}
        />
      </>
    )
  }

  if (listFieldsData.isError) {
    return <FormError reload={listFieldsData.refetch} />
  }

  return (
    <FormLayout onSubmit={onSubmit}>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gridRowGap={8}
        gridColumnGap={6}
        data-testid="PremadeForm"
      >
        <GridItem colSpan={2}>
          <Input
            {...register('name')}
            {...getFieldValidations('name')}
            label={t('form.name_field.label')}
            placeholder={t('form.name_field.placeholder')}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Input
            {...register('email')}
            {...getFieldValidations('email', { isTouched: true })}
            label={t('form.email_field.label')}
            placeholder={t('form.email_field.placeholder')}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Input
            {...register('phone')}
            {...getFieldValidations('phone')}
            label={t('form.phone_field.label')}
            placeholder="Ej. +56 9 87654321"
            isRequired
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Input
            {...register('subdomain')}
            {...getFieldValidations('subdomain')}
            label={t('form.tenant_field.label')}
            placeholder={t('form.tenant_field.placeholder')}
            isRequired
            w={{ base: '100%', md: '52%' }}
            prefixInput={
              <InputLeftAddon
                children={'demo'}
                fontSize="sm"
                fontWeight="semibold"
                color="secondary.02"
                bg="neutral.01"
                borderColor="neutral.01"
                w={{ md: '14%' }}
              />
            }
            suffix={
              <InputRightAddon
                children={domain}
                fontSize="sm"
                fontWeight="semibold"
                color="secondary.02"
                bg="neutral.01"
                borderColor="neutral.01"
                w={{ md: '36%' }}
              />
            }
          />
        </GridItem>

        <GridItem colSpan={2}>
          <Input
            {...register('password')}
            {...getFieldValidations('password', { hideErrorMessage: true })}
            label={t('form.password_field.label')}
            placeholder={t('form.password_field.placeholder')}
            type={showPassword ? 'text' : 'password'}
            data-testid="PremadeForm-password"
            isRequired
            suffix={
              <InputRightElement>
                <IconButton
                  size="sm"
                  fontSize="md"
                  variant="ghost"
                  aria-label={t('form.password_field.placeholder')}
                  icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                  onClick={handleClickEye}
                  tabIndex={-1}
                />
              </InputRightElement>
            }
            helperMessage={
              <FormPasswordHelperMessages
                isTouchedAndDirty={isTouchedAndDirtyField('password')}
                passwordErrors={errors?.password}
              />
            }
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <SelectFlag
                {...field}
                {...getFieldValidations('country')}
                defaultValue={getDefaultOption(listFieldsData.data.countries)}
                label={t('form.country_field.label')}
                placeholder={t('form.country_field.placeholder')}
                isRequired
                options={listFieldsData.data.countries}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Controller
            name="purpose"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                {...getFieldValidations('purpose')}
                label={t('form.purpose_field.label')}
                placeholder={t('form.purpose_field.placeholder')}
                isRequired
                defaultValue={getDefaultOption(listFieldsData.data.purposes)}
                options={listFieldsData.data.purposes}
              />
            )}
          />
        </GridItem>

        <GridItem
          colSpan={2}
          justifyContent={{ base: 'center', md: 'space-between' }}
          flexDir={{ base: 'column-reverse', md: 'row' }}
          display={
            dirtyFields?.subdomain && !errors?.subdomain ? 'flex' : 'none'
          }
          alignItems="center"
          mb="5"
        >
          <Checkbox
            {...register('confirm')}
            color="secondary.02"
            isInvalid={!!errors?.confirm}
            data-testid="PremadeForm-Checkbox"
            isChecked={confirm}
          >
            <Flex alignItems="center">
              <Flex
                flexDirection="column"
                ml="3"
                position="relative"
                alignItems="center"
              >
                <Text fontSize="sm">
                  {t('form.confirm')}{' '}
                  <Text as="strong">{`demo${watchedSubdomain}${domain}`}</Text>
                </Text>

                {!!errors?.confirm && (
                  <Text
                    position="absolute"
                    left="0"
                    bottom="0"
                    mb="-5"
                    fontSize="xs"
                    color="support.error"
                  >
                    {t('form.validations.confirm')}
                  </Text>
                )}
              </Flex>

              {!!errors?.confirm && (
                <Flex alignItems="center">
                  <Icon
                    color="support.error"
                    as={HiOutlineExclamationCircle}
                    fontSize="xl"
                  />
                </Flex>
              )}
            </Flex>
          </Checkbox>
        </GridItem>

        <GridItem
          colSpan={2}
          justifyContent={{ base: 'center', md: 'space-between' }}
          flexDir={{ base: 'column-reverse', md: 'row' }}
          display="flex"
          alignItems="center"
        >
          <Link
            fontSize="sm"
            color="primary.01"
            href={t('form.back_link')}
            px="10"
            py="4"
            tabIndex={-1}
          >
            {t('form.back')}
          </Link>
          {/* In production, if the button is enabled and clicked when there are validation errors,
              a contact on Hubspot will be created anyway, and according to https://knowledge.hubspot.com/forms/use-non-hubspot-forms
              that is the intented behavior. To avoid this we disable the button in that scenario.
              Hubspot JS code is loaded via Google Tag Manager. */}
          <Button
            id="demo-submit"
            size={buttonSize}
            fontSize="md"
            colorScheme="blue"
            bg="primary.01"
            isDisabled={!isFormValid || !confirm}
            type="submit"
          >
            {t('form.submit')}
          </Button>
        </GridItem>
      </Grid>

      <Text fontSize="sm" mt="6" color="secondary.01">
        *{t('form.required_fields')}
      </Text>

      <ReCAPTCHA
        sitekey={CONSTANTS.RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={recaptchaRef}
      />
    </FormLayout>
  )
}

export default PremadeForm

import { useEffect, useRef } from 'react'

function usePreventLeavingSite(when: boolean) {
  const whenRef = useRef(when)

  const byPass = (fn: () => void) => {
    whenRef.current = false
    fn()
    whenRef.current = when
  }

  useEffect(() => {
    whenRef.current = when
  })

  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      if (!whenRef.current) return

      e.preventDefault()
      e.returnValue = ''
    }

    window.addEventListener('beforeunload', onUnload)

    return () => window.removeEventListener('beforeunload', onUnload)
  }, [])

  return { byPass }
}

export default usePreventLeavingSite

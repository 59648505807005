import debounce from 'p-debounce'
import { fetcher } from 'features/shared/utils/fetcher'
import memoize from 'p-memoize'

const debounceDelay = 100
async function isTenantAvailable(tenant?: string) {
  if (!tenant) return false

  try {
    await fetcher.get(`/v1/leads/${tenant}`, {
      headers: {
        accept: '*/*',
      },
    })

    return true
  } catch {
    return false
  }
}

export default debounce(memoize(isTenantAvailable), debounceDelay)

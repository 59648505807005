export const hubspotUtk = (email: string) => {
  const utk = document.cookie
    .split('; ')
    .find((row) => row.startsWith('hubspotutk'))
    ?.split('=')[1]

  if ((window as any)._hsq) {
    ;(window as any)._hsq.push([
      'identify',
      {
        email: email,
        utk: utk,
      },
    ])
  }

  return utk
}

import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'
import { build, fake } from '@jackfranklin/test-data-bot'

const country = build({
  fields: {
    iso_2: fake((f) => f.address.countryCode()),
    name: fake((f) => f.address.country()),
    currency_code: fake((f) => f.finance.currencyCode().toLowerCase()),
  },
})

const countriesBuilder = build({
  fields: [],
  postBuild: () => {
    return [
      {
        iso_2: 'pe',
        name: 'Peru',
        currency_code: 'pen',
      },
      {
        iso_2: 'cl',
        name: 'Chile',
        currency_code: 'clp',
      },
      ...Array(20)
        .fill(undefined)
        .map((_) => country()),
    ]
  },
})

const countriesHandler = rest.get(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/products/countries`,
  (_, res, ctx) => {
    return res(ctx.json(countriesBuilder()))
  }
)

export default countriesHandler

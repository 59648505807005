import countriesHandler from './handlers/countriesHandler'
import currenciesHandler from './handlers/currenciesHandler'
import createTenantHandler from './handlers/createTenantHandler'
import locationHandler from './handlers/locationHandler'
import timeZonesHandler from './handlers/timeZonesHandler'
import validateTenantHandler from './handlers/validateTenantHandler'
import statusTenantHandler from './handlers/statusTenantHandler'
import tenantUrlHandler from './handlers/tenantUrlHandler'

export const handlersBrowser = [
  countriesHandler,
  locationHandler,
  timeZonesHandler,
  currenciesHandler,
  createTenantHandler,
  validateTenantHandler,
  statusTenantHandler,
  tenantUrlHandler,
]

export const handlersNode = [...handlersBrowser]

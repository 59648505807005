import { fetcher } from 'features/shared/utils/fetcher'
import { country } from '../types/countryType'

async function getCountries() {
  const data = await fetcher.get<country[]>('/v1/products/countries')

  return data
}

export default getCountries

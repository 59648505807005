import { CONSTANTS } from './constants'
import I18nWrapper from './i18n/I18nWrapper'

if (CONSTANTS.IS_MOCK_ENV) {
  const { worker } = require('__mocks__/server/browser')
  worker.start()
}

const ConfigsWrapper: React.FC = ({ children }) => {
  return <I18nWrapper>{children}</I18nWrapper>
}

export default ConfigsWrapper

import { Box, Flex, BoxProps } from '@chakra-ui/react'

const Layout: React.FC = ({ children }) => {
  const absoluteBox: BoxProps = {
    pos: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
  }

  return (
    <Flex
      minH="100vh"
      pos="relative"
      alignItems="center"
      justifyContent="center"
      data-testid="Layout"
    >
      <Box {...absoluteBox} w="50%" display={{ base: 'none', lg: 'block' }}>
        <Box
          {...absoluteBox}
          backgroundSize="cover"
          backgroundPosition="center"
        />
        <Box {...absoluteBox} bg="primary.04" opacity="0.95" />
        <Box {...absoluteBox} bg="secondary.01" opacity="0.6" />
      </Box>

      <Flex pos="relative" w="full" justifyContent="center" maxW="container.xl">
        {children}
      </Flex>
    </Flex>
  )
}

export default Layout

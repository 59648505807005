import { Button } from '@chakra-ui/button'
import { Text } from '@chakra-ui/layout'
import { useTranslation } from 'react-i18next'
import FormLayout from './FormLayout'

interface FormErrorProps {
  reload: () => void
}

const FormError: React.FC<FormErrorProps> = ({ reload }) => {
  const { t } = useTranslation()

  return (
    <FormLayout>
      <Text fontWeight="semibold" mt="12" data-testid="FormError-Text">
        {t('form.loading_error')}
      </Text>{' '}
      <Button colorScheme="blue" mt="4" onClick={reload}>
        {t('form.reload')}
      </Button>
    </FormLayout>
  )
}

export default FormError

import { fetcher } from 'features/shared/utils/fetcher'
import { location } from '../types/locationType'

async function getCurrentLocation() {
  const data = await fetcher.get<location>('/v1/location')

  return data
}

export default getCurrentLocation

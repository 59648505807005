import { SimpleGrid } from '@chakra-ui/react'
import FormPasswordHelperText from './FormPasswordHelperText'
import { useTranslation } from 'react-i18next'

type PasswordErrorType = {
  types: object
}

interface HelperMessagesProps {
  passwordErrors: PasswordErrorType
  isTouchedAndDirty: boolean
}

const FormPasswordHelperMessages: React.FC<HelperMessagesProps> = ({
  passwordErrors,
  isTouchedAndDirty,
}) => {
  const { t } = useTranslation()

  return (
    <SimpleGrid columns={2}>
      <FormPasswordHelperText
        isTouchedAndDirty={isTouchedAndDirty}
        passwordErrors={passwordErrors}
        passwordErrorType="min"
        text={t('form.password_rules.minimum')}
      />
      <FormPasswordHelperText
        isTouchedAndDirty={isTouchedAndDirty}
        passwordErrors={passwordErrors}
        passwordErrorType="upperCaseTest"
        text={t('form.password_rules.uppercase')}
      />
      <FormPasswordHelperText
        isTouchedAndDirty={isTouchedAndDirty}
        passwordErrors={passwordErrors}
        passwordErrorType="lowerCaseTest"
        text={t('form.password_rules.lowercase')}
      />
      <FormPasswordHelperText
        isTouchedAndDirty={isTouchedAndDirty}
        passwordErrors={passwordErrors}
        passwordErrorType="numberTest"
        text={t('form.password_rules.number')}
      />
      <FormPasswordHelperText
        isTouchedAndDirty={isTouchedAndDirty}
        passwordErrors={passwordErrors}
        passwordErrorType="specialCharacterTest"
        text={t('form.password_rules.special_character')}
      />
    </SimpleGrid>
  )
}

export default FormPasswordHelperMessages

import { CONSTANTS } from 'configs/constants'
import { rest } from 'msw'

const tenantUrlHandler = rest.get(
  `${CONSTANTS.THE_ADMIN_API_URL}/v1/leads/*/tenant`,
  (_, res, ctx) => {
    return res(ctx.json({ tenant: 'https://random.timebillingapp.com' }))
  }
)

export default tenantUrlHandler
